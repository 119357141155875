.App {
  text-align: center;
}

body {
  background-color:#f7f6f5;
}



.App-link {
  color: #61dafb;
}


.datepicker {
  border-radius: 4px;
  padding: 8px;
  border: 1px solid darkgray;
  cursor: pointer;
}

.datepicker-wrapper {
  display: flex;
  align-items: center;
}

.toggle-rent-infos {
  display: flex;
  flex-direction: column;
}

#outlined-required {
  border-color: red;
}

.st0 {
  stroke: none;
}

.lightboxImg {
  height: 100%;
  width: 100%;
  object-fit: "cover",
}

._30i9K img {
  height: 158px;
  object-fit: cover;
};

.status {
  width: min-content;
}

.no-autofill:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: #000;
}
